import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"
/* import LazyLoad from "react-lazyload" */

import Link from "../Link"
import Section from "../Section"
import { Heading3, Heading4 } from "../Heading"

import ceskaTelevizeLogo from "../../images/partners/ceska_televize.svg"

const Partners = ({ styles, rules }) => {
  const images = useStaticQuery(graphql`
    {
      mzcr: file(
        relativePath: { regex: "/mzcr.(.*)$/" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 200, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      nadace_vodafone: file(
        relativePath: { regex: "/nadace_vodafone.(.*)$/" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      fnkv: file(relativePath: { regex: "/fnkv.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      klub_afasie: file(relativePath: { regex: "/klub_afasie.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      benu: file(relativePath: { regex: "/benu.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 80, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      akl: file(relativePath: { regex: "/akl.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      pierstone: file(relativePath: { regex: "/pierstone.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 30, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      ffuk: file(relativePath: { regex: "/ffuk.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      juls: file(relativePath: { regex: "/juls.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 200, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      gml: file(relativePath: { regex: "/gml.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      kpmg: file(relativePath: { regex: "/kpmg.(.*)$/" }) {
        childImageSharp {
          gatsbyImageData(height: 100, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)

  return (
    /* <LazyLoad height={200}> */
    <Section>
      <FormattedMessage id="partners.title">
        {title => <Heading3 customStyle={rules.title}>{title}</Heading3>}
      </FormattedMessage>
      {
        <div className={styles.partners}>
          {[
            {
              web: "https://mzd.gov.cz/",
              name: "Ministerstvo zdravotnictví ČR",
              img: images.mzcr,
            },
            {
              web: "https://www.nadacevodafone.cz/",
              name: "Nadace vodafone",
              img: images.nadace_vodafone,
            },
            {
              web: "https://www.fnkv.cz/",
              name: "Fakultní nemocnice Vinohrady",
              img: images.fnkv,
            },
            {
              web: "http://www.klubafasie.com/",
              name: "Občanské sdružení klub Afasie",
              img: images.klub_afasie,
            },
            {
              web: "https://www.benulekaren.sk/",
              name: "Benu Lekáreň",
              img: images.benu,
            },
            {
              web: "https://www.pierstone.com/",
              name: "Pierstone",
              img: images.pierstone,
            },
            {
              web: "https://www.ff.cuni.cz/",
              name: "Filozofická fakulta Univerzity Karlovy",
              img: images.ffuk,
            },
            {
              web: "https://www.juls.savba.sk/index.html",
              name: "Jazykovědný ústav Ľudovíta Štúra",
              img: images.juls,
            },
            {
              web: "http://www.klinickalogopedie.cz/",
              name: "Asociace klinických logopedů ČR",
              img: images.akl,
            },
            {
              web: "https://gml.vse.cz/",
              name: "Grafická a multimediální laboratoř",
              img: images.gml,
            },
          ].map(({ web, name, img, width, height }) => (
            <Link
              type="external"
              href={web}
              key={name}
              customStyle={rules.partner}
              styleProps={{ width, height }}
            >
              <GatsbyImage
                image={img.childImageSharp.gatsbyImageData}
                alt={name}
                objectFit="contain"
              />
            </Link>
          ))}
        </div>
      }
      <FormattedMessage id="partners.media.title">
        {title => <Heading4 customStyle={rules.subTitle}>{title}</Heading4>}
      </FormattedMessage>

      <div className={styles.partners}>
        <Link
          type="external"
          href="https://www.ceskatelevize.cz/"
          customStyle={rules.partner}
        >
          <img
            alt="Česká televize"
            src={ceskaTelevizeLogo}
            style={{ height: 50 }}
          />
        </Link>
      </div>

      <FormattedMessage id="partners.supporters.title">
        {title => <Heading4 customStyle={rules.subTitle}>{title}</Heading4>}
      </FormattedMessage>

      <div className={styles.partners}>
        {[
          {
            web: "http://www.kpmg.cz/",
            name: "KPMG",
            img: images.kpmg,
          },
        ].map(({ web, name, img }) => (
          <Link
            type="external"
            href={web}
            key={name}
            customStyle={rules.partner}
          >
            <GatsbyImage
              image={img.childImageSharp.gatsbyImageData}
              alt={name}
              objectFit="contain"
            />
          </Link>
        ))}
      </div>
    </Section>
    /* </LazyLoad> */
  )
}

Partners.propTypes = {
  styles: PropTypes.shape({
    partners: PropTypes.string.isRequired,
  }).isRequired,
}

export default Partners
